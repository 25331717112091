.App {
  display: flex;
}

html,
body {
  height: 100%;
  background-color: #e2e2eb;
}

div#root {
  height: 100%;
}

.App {
  height: 100%;
}

.badge {
  padding: 3px 6px;
  font-size: 9px;
  letter-spacing: 1px;
  border-radius: 15px;

  &.red {
    color: #ffffff;
    background: #d63030;
  }

  &.gray {
    color: #ffffff;
    background: #777777;
  }

  &.yellow {
    color: #000000;
    background: #ffd331;
  }
}

button span.pi {
  font-size: 14px !important;
}

label {
  padding-left: 5px;
}

// Mensagen de erro dos inputs
small.p-error {
  font-size: 9px;
  margin-left: 10px;
}

// Cabeçalho dos dialogs
.p-dialog-header {
  padding: 1rem !important;
}

// Rodapé dos dialogs
.p-dialog-footer {
  padding: 0 1rem 1rem 1rem !important;
}

// Input dentro dos dialogs
.p-fluid .p-inputtext {
  margin-top: 5px;
  font-size: 12px !important;
}

// Numero de paginas das tabelas
.p-paginator .p-paginator-pages .p-paginator-page {
  font-size: 12px !important;
}

// Seta na paginação das tabelas
span.p-dropdown-trigger-icon.p-clickable.pi.pi-chevron-down {
  font-size: 12px !important;
}

// Dropdown da paginação da tabela
.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled {
  height: auto !important;
}

//Numero dentro do Dropdown da paginação da tabela
.p-paginator .p-dropdown .p-dropdown-label {
  padding: 0.3rem !important;
  font-size: 12px !important;
}

// Input pesquisa do header
input.p-inputtext.p-component.p-autocomplete-input {
  font-size: 13px;
}

.autoCompleteNavbar input {
  width: 300px;
}

// Texto dropdown
span.p-dropdown-label.p-inputtext {
  margin: 0;
}

// Input do auto complite 
span.p-dropdown-label.p-inputtext.p-placeholder {
  margin: 0 !important;
}

// texto Dialog Deletar
.p-dialog .p-dialog-content {
  padding: 16px !important;
}

// input filtro tabela
input.p-inputtext.p-component.p-column-filter {
  max-width: -webkit-fill-available;
}

//  menu
.pro-sidebar-layout {
  background-color: #1e1e2d;
}

.react-slidedown.pro-inner-list-item {
  background-color: #181825;
}

nav.pro-menu.shaped.circle {
  padding-right: 0;
}

.menuPrimario {
  font-size: 14px !important;
  font-weight: bold !important;
}

.menuSecundario {
  font-size: 14px !important;
  font-weight: normal !important;
}

.pro-inner-item {
  height: 44px;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item>div>ul {
  padding-top: 5px;
  padding-bottom: 5px;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item {
  background-color: #181825;
}

.pro-sidebar .pro-menu>ul>.pro-sub-menu>.pro-inner-list-item>div>ul {
  background-color: #181825;
}

.popper-inner {
  height: 0 !important;
}

.pro-inner-list-item.popper-element {
  visibility: hidden;
}

.pro-icon-wrapper {
  font-size: 20px !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
  overflow: hidden;
}

.p-dialog-header {
  padding: 1rem 1rem 0 1rem !important;
}

svg:hover path {
  fill: #009ef7 !important;
}

.pro-inner-item:hover svg path {
  fill: #009ef7 !important;
  color: #009ef7 !important;
}