.navbar {
  position: fixed;
  z-index: 1;
  width: 100%;
  background: #ffffff;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.2rem;
  box-shadow: 0 10px 30px 0 rgb(82 63 105 / 8%);
}

.navbar-logo {
  color: #fff;
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: 2rem;
}

.fa-firstdraft {
  margin-left: 0.5rem;
  font-size: 1.6rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 70vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  display: flex;
  align-items: center;
  height: 80px;
}

.nav-links {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
}

.nav-links:hover {
  background-color: #1888ff;
  border-radius: 4px;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: #242222;
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    text-align: center;
    padding: 2rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    background-color: #1888ff;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(25%, 50%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
  }

  .fa-times {
    color: #fff;
    font-size: 2rem;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    padding: 1.5rem;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    background: #1888ff;
    text-decoration: none;
    color: #fff;
    font-size: 1.5rem;
  }

  .nav-links-mobile:hover {
    background: #fff;
    color: #1888ff;
    transition: 250ms;
  }

  button {
    display: none;
  }
}

nav {
  padding-right: 15px;
  justify-content: flex-end;
}

nav div {
  display: flex;
  align-items: center;
}

nav div p {
  margin-right: 5px;
  font-size: 16px;
  color: gray;
}

button.p-button-lg span.pi {
  font-size: 20px;
}

button.p-button.p-component.p-button-text.p-button-rounded {
  padding: 0;
}

.cardMenu {
  top: 50px;
  right: 30px;
  height: 165px;
  position: fixed;
  display: flow-root;
  border-radius: 0px 0px .475rem .475rem;
  background-color: white;
  box-shadow: 0 0px 25px 0 rgb(82 63 105 / 15%)
}

.opcaoMenu {
  display: grid;
  height: 110px;
}

.opcaoMenu :hover {
  background-color: #f7fafb;
}

.linkMenu {
  cursor: pointer;
  padding: 3px 15px;
  height: -webkit-fill-available;
}

.linkMenu p {
  margin: 0;
  font-size: 13px;
  color: #3f4254;
}

.linkLabel {
  display: flex;
  padding: 3px 15px;
  align-items: center;

  height: -webkit-fill-available;
  width: -webkit-fill-available;

  color: #3f4254;
  font-size: 13px;
  text-decoration: none;
}

.p-button:focus {
  box-shadow: none !important;
}

img.sb-avatar__image {
  min-width: 40px;
  min-height: 40px;
}