.sidebar {
  z-index: 1;
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
}

// Tamanho da fonte no menu
.p-panelmenu .p-menuitem-text {
  font-size: 12px !important;
}

// Tamanho e cor dos icones do menu
.p-menuitem-icon {
  color: #494b74 !important;
  font-size: 18px !important;
}

.p-panelmenu .p-panelmenu-header>a {
  padding: 7px 10px !important;
  border: 1px solid #262635 !important;
  color: #adadad !important;
  background: #262635 !important;
}

.p-panelmenu .p-panelmenu-header.p-highlight:not(.p-disabled)>a:hover {
  border: 1px solid #262635 !important;
  color: #adadad !important;
  background: #262635 !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  padding: 7px 1rem !important;
  border: 1px solid #262635 !important;
  color: #adadad !important;
  background: #262635 !important;
}

.p-panelmenu .p-panelmenu-panel .p-panelmenu-content {
  border: 1px solid #262635 !important;
  color: #adadad !important;
  background: #262635 !important;
}

.p-panelmenu .p-panelmenu-content .p-submenu-list:not(.p-panelmenu-root-submenu) {
  border: 1px solid #262635 !important;
  color: #adadad !important;
  background: #262635 !important;
}

.p-panelmenu .p-panelmenu-header>a:focus {
  box-shadow: inset 0 0 0 0.15rem #262635 !important;
}

.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link:focus {
  box-shadow: inset 0 0 0 0.15rem #262635 !important;
}

.p-panelmenu-icon {
  color: #5c5e81 !important;
}

.p-menuitem-text {
  color: #a2a3b7 !important;
}

.p-panelmenu-icon {
  color: #5c5e81 !important;
  font-size: 14px !important;
}

.p-menuitem-text {
  color: #a2a3b7 !important;
}

.p-menuitem-text:hover {
  color: #ffffff !important;
}

.setaMenu:hover {
  color: #3699ff !important;
}

.p-button.p-button-icon-only {
  width: 30px !important;
  height: 30px !important;
  padding: 0 !important;
}