@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"),
    url("./assets/fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "PoppinsBold";
  src: local("PoppinsBold"),
    url("./assets/fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: bold;
}

body {
  margin: 0;
  font-size: 14px !important;
  font-weight: 400;
  font-family: PoppinsRegular, Helvetica, sans-serif !important;
  -webkit-font-smoothing: antialiased
}

.p-component {
  font-size: 14px !important;
  font-family: PoppinsRegular, Helvetica, sans-serif !important;
}

.card {
  background: var(--surface-e);
  padding: 2rem;
  -webkit-box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
  border-radius: 4px;
  margin-bottom: 2rem;
}

.table-badge {
  background: #FEEDAF;
  color: #8A5340;
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 10px !important;
  letter-spacing: .3px;
}

.table-badge.status-admin {
  background: #FEEDAF;
  color: #8A5340;
}

.table-badge.status-normal {
  background: #c7cfe8;
  color: #475ac7;

}

.table-badge.status-ativo {
  background: #afcefe;
  color: #405a8a;
}

.table-badge.status-inativo {
  background: #fffc48;
  color: #b77443;
}

.table-badge.status-aguardando {
  background: #afdcfe;
  color: #4d408a;
}

.table-badge.status-andamento {
  background: #feedaf;
  color: #8a5340;
}

.table-badge.status-cancelado {
  background: #FEEDAF;
  color: #8A5340;
}

.table-badge.status-concluido {
  background: #c8e6c9;
  color: #256029;
}

span.p-column-title {
  font-size: 13px;
}

.p-datatable-resizable .p-datatable-thead>tr>th,
.p-datatable-resizable .p-datatable-tfoot>tr>td,
.p-datatable-resizable .p-datatable-tbody>tr>td {
  font-size: 13px !important;
}

.table-header {
  align-self: center !important;
}

h5.p-m-0 {
  font-size: 20px !important;
  margin-left: 5px !important;
}